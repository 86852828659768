import React from "react";
import styled from "styled-components";

import links from "@data/links";
import { setFlex, setRem, setColor, setTransition } from "@global/helper";

const Footer = ({ className }) => {
  return (
    <div className={className}>
      <h3 className="h3-footer">
        Todos os direitos reservados © Estúdio Liz, 2022
      </h3>
      <h4>
        Desenvolvido por
        <a target="_blank" rel="noreferrer" href={links.developedBy.link}>
          Arthur Kunzler
        </a>
      </h4>
    </div>
  );
};

export default styled(Footer)`
  background: ${setColor.primaryColor};
  padding: ${setRem(64)};
  ${setFlex()};
  flex-direction: column;
  font-size: ${setRem(18)};
  ${setTransition()};

  .h3-footer {
    margin-bottom: ${setRem(26)};
  }

  a {
    padding: ${setRem(5)};
    text-decoration: none;
    color: ${setColor.secondaryColor};
  }
  a:hover {
    text-decoration: overline underline;
    font-size: ${setRem(22)};
  }
`;
