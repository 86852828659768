import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faInstagram, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import lizCaramelo from "@assets/imgs/caramelo.svg";

const links = {
  instagram: {
    title: "Instagram",
    content: "@estudio.liz",
    link: "https://www.instagram.com/estudio.liz/",
    icon: <FontAwesomeIcon key="instagram" icon={faInstagram} />,
  },
  email: {
    title: "Email",
    content: "lizquintanaliteratura@gmail.com",
    link: "mailto:lizquintanaliteratura@gmail.com",
    icon: <FontAwesomeIcon key="email" icon={faEnvelope} />,
  },
  linkedin: {
    title: "LinkedIn",
    link: "https://www.linkedin.com/in/liz-quintana-07a535155/",
    icon: <FontAwesomeIcon key="linkedin" icon={faLinkedin} />,
  },
  developedBy: {
    title: "developedBy",
    link: "https://www.linkedin.com/in/arthur-kunzler-696470195/",
  },
  estudioLiz: {
    title: "estudioLiz",
    content: "EstudioLiz",
    link: "https://estudioliz.com.br/",
    icon: <img height="35" src={lizCaramelo} alt="logo Estudio"></img>,
  },
};

export default links;
