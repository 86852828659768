import React from "react";
import styled from "styled-components";

import Section from "@components/atoms/Section";
import { setRem, setColor } from "@global/helper";
import ContactInfo from "@components/organisms/ContactInfo";
import Title from "@components/atoms/Title";

const Contact = ({ className }) => {
  return (
    <Section
      className={className}
      color={`linear-gradient(
${setColor.primaryColor}80,
${setColor.primaryColor}e0
)`}
      padding={`${setRem(32)}`}
    >
      <Title title="Entre em contato!" size={setRem(36)} center />
      <ContactInfo />
    </Section>
  );
};

export default styled(Contact)`
  min-height: 65vh;
`;
