import React from "react";
import styled from "styled-components";
import { setColor, setFlex } from "@global/helper";

import Navbar from "@components/molecules/Navbar";

const Header = ({ className }) => {
  return (
    <div className={className}>
      <Navbar />
    </div>
  );
};

export default styled(Header)`
  background: ${setColor.secondaryColor};
  height: 15vh;
  color: ${setColor.primaryColor};
  ${setFlex()};
`;
