import GlobalStyle from "@global/GlobalStyles";
import Header from "@components/organisms/Header";
import Footer from "@components/organisms/Footer";
import Routes from "@src/Routes";

import { BrowserRouter } from "react-router-dom";
function App() {
  return (
    <BrowserRouter>
      <GlobalStyle />
      <Header />
      <Routes />
      <Footer />
    </BrowserRouter>
  );
}

export default App;
