import React from "react";
import styled from "styled-components";

import { setColor, setRem } from "@global/helper";
import Section from "@components/atoms/Section";
import TextContent from "@components/molecules/TextContent";
import TextLinks from "@components/organisms/TextLinks";

const Texts = ({ textContent = <div></div>, className }) => {
  const { title, content } = textContent;
  return (
    <Section
      className={className}
      color={`linear-gradient(
  ${setColor.primaryColor}80,
  ${setColor.primaryColor}e0
)`}
      padding={`${setRem(32)}`}
    >
      <TextContent title={title} text={content} />
      <TextLinks />
    </Section>
  );
};

export default styled(Texts)`
  min-height: 65vh;
`;
