import React from "react";
import styled from "styled-components";

import { setColor, setRem, media } from "@global/helper";
import profilePhoto from "@assets/imgs/profile2.jpeg";
import AboutInfo from "@components/molecules/AboutInfo";
import ProfilePhoto from "@components/atoms/ProfilePhoto";
import Section from "@components/atoms/Section";

const AboutMe = () => {
  const aboutInfoText = `   Sou a Liz Quintana. Designer, ilustradora e escritora. Percebo no desenho, leitura e escrita o encontro do ficcional e suas múltiplas possibilidades. Conclui o Curso Livre de Formação de Escritores da Metamorfose.     
  
  Participo de oficinas e cursos relacionados a Literatura Infanto- Juvenil e acredito que o fazer literário é o espaço certo para o imaginário de meus projetos e ilustrações.  

  Publiquei em duas coletâneas, Minicontos de Amor e Morte (2018) e Banquete (2019). Meu primeiro livro individual, Traços (2019), escrito e ilustrado por mim, foi finalista do Prêmio AEILIJ 2019 na categoria juvenil.
  
  Em 2021, publiquei o livro Iogurte, voltado para o público infantil. Neste livro, trabalhei todo o design editorial, assim como a escrita e ilustração.

  O livro O Mistério do Picolé (2022) escrito em coautoria com a Berenice Copstein, é infanto-juvenil. Neste mesmo ano, o livro Chamas foi lançado como parte de uma série denominada Novelas Porto-alegrenses em homenagem aos 250 anos da capital do Estado.`;

  return (
    <Section padding={setRem(24)}>
      <AboutWrapper>
        <ProfilePhoto
          className="about-img"
          img={profilePhoto}
          borderColor={setColor.secondaryColor}
        />
        <AboutInfo
          className="about-info-component"
          title="Sobre mim"
          text={aboutInfoText}
        />
      </AboutWrapper>
    </Section>
  );
};

const AboutWrapper = styled.div`
  margin: 2vh auto 0 auto;
  width: 100%;
  .about-info {
    margin-top: ${setRem(24)};
  }

  ${media.phone`
    padding: 0 ${setRem(32)};
  `}

  ${media.large`
    padding: 0 ${setRem(64)};
    width: 100%;
    max-width: 1170px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: ${setRem(24)};

    .about-img,
    .about-info {
        padding: ${setRem(0)};
        align-self: center;
        margin-top: ${setRem(0)};
    }
    .about-info-component h3 {
      text-align: left;
    }
    .about-info p{
    width: 90%;        
    }
  `}
`;

export default AboutMe;
