import React from "react";
import styled from "styled-components";
import NavItem from "@components/atoms/NavItem";
import { setFlex } from "@global/helper";

const Navbar = ({ className }) => {
  return (
    <nav className={className}>
      <NavItem path="/">início</NavItem>
      <NavItem path="/livros">livros</NavItem>
      <NavItem path="/textos">textos</NavItem>
      <NavItem path="/contato">contato</NavItem>
      <NavItem path="/" logo>
        liz quintana
      </NavItem>
    </nav>
  );
};

export default styled(Navbar)`
  width: 100vw;
  margin: 0 20%;

  ${setFlex()};
  justify-content: space-around;
`;
