import { createGlobalStyle } from "styled-components";
import { setColor, setFont } from "@global/helper";

const Globals = createGlobalStyle`
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
body {
    font-size: 100%;
    color: ${setColor.secondaryColor};
    ${setFont.main};
    p {
        white-space: pre-wrap;
    }
}
`;

export default Globals;
