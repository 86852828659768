import React from "react";

import { setColor, setRem } from "@global/helper";
import dataLinks from "@data/links";
import Section from "@components/atoms/Section";
import AboutMe from "@components/organisms/AboutMe";
import ContactLinks from "@components/molecules/ContactLinks";

const Home = () => {
  return (
    <Section
      color={`linear-gradient(
      ${setColor.primaryColor}80,
      ${setColor.primaryColor}e0
    )`}
      padding={`${setRem(32)}`}
    >
      <AboutMe />
      <ContactLinks
        title="Vamos nos conectar!"
        links={[dataLinks.instagram]}
        type="title"
      />
    </Section>
  );
};

export default Home;
