import imgTracos from "@assets/imgs/tracos.jpg";
import imgBanquete from "@assets/imgs/banqueteP.jpg";
import imgMinicontos from "@assets/imgs/minicontosdeamoremorte.jpg";
import imgChamas from "@assets/imgs/chamas-capa-img.png";
import imgMisterioPicole from "@assets/imgs/misterio-picole-img.png";
import imgIogurte from "@assets/imgs/Iogurte.svg";
import links from "./links";

const books = [
  {
    id: 6,
    img: imgChamas,
    title: "Chamas",
    author: "Liz Quintana",
    kind: "Juvenil",
    year: "2022",
    link: links.instagram.link,
  },
  {
    id: 5,
    img: imgMisterioPicole,
    title: "o Mistério do Picolé",
    author: "Liz Quintana e Berenice Copstein",
    kind: "Infanto-juvenil",
    year: "2022",
    link: links.instagram.link,
  },
  {
    id: 4,
    img: imgIogurte,
    title: "Iogurte",
    author: "Liz Quintana",
    kind: "Infantil",
    year: "2021",
    link: links.instagram.link,
  },
  {
    id: 1,
    img: imgTracos,
    title: "Traços",
    author: "Liz Quintana",
    kind: "Juvenil",
    year: "2019",
    link: "https://www.amazon.com.br/Tra%C3%A7os-Liz-Quintana/dp/8553074676/ref=sr_1_1?__mk_pt_BR=%C3%85M%C3%85%C5%BD%C3%95%C3%91&crid=2CQBZMP3CGA87&dchild=1&keywords=tra%C3%A7os+liz+quintana&qid=1617718579&sprefix=tra%C3%A7os%2Caps%2C312&sr=8-1",
  },
  {
    id: 2,
    img: imgBanquete,
    title: "Banquete",
    author: "Vários autores",
    kind: "Contos",
    year: "2019",
    link: "https://www.amazon.com.br/dp/855307448X/ref=olp_aod_early_redir?_encoding=UTF8&aod=1",
  },
  {
    id: 3,
    img: imgMinicontos,
    title: "Minicontos de Amor e Morte",
    author: "Vários autores",
    kind: "Minicontos",
    year: "2018",
    link: "https://www.amazon.com.br/dp/8553074366/ref=olp_aod_early_redir?_encoding=UTF8&aod=1",
  },
];

export default books;
