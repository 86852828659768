import React from "react";
import styled from "styled-components";
import { setRem, setFont, setLetterSpacing, media } from "@global/helper";

const Title = ({ className, title }) => {
  return <h3 className={className}>{title}</h3>;
};

export default styled(Title)`
  font-size: ${(props) => (props.size ? props.size : setRem(30))};
  ${setLetterSpacing(5)};
  ${setFont.slanted};
  text-align: ${(props) => (props.center ? "center" : "left")};
  padding-bottom: ${setRem(8)};
  ${media.tablet`
    font-size: ${(props) =>
      props.size ? `calc(${props.size} + ${setRem(16)})` : setRem(36)};  
  `}
`;
