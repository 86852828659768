import React from "react";
import { Switch, Route, Redirect } from "react-router";

import Home from "@pages/Home";
import Books from "@pages/Books";
import Texts from "@pages/Texts";
import TextRoutes from "@src/TextRoutes";
import Contact from "@pages/Contact";
import textData from "@data/texts";

const Routes = () => {
  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route path="/livros" component={Books} />
      <Route path="/contato" component={Contact} />
      <Route exact path="/textos" component={Texts} />
      <TextRoutes textData={textData} />
      <Redirect from="*" to="/" />
    </Switch>
  );
};

export default Routes;
