import React, { useState } from "react";
import { Route } from "react-router";
import Texts from "@pages/Texts";

const TextRoutes = ({ textData }) => {
  const [texts] = useState(textData);
  return (
    <>
      {texts &&
        texts.map((text) => (
          <Route
            key={text.id}
            path={`/textos/${text.id}`}
            component={() => <Texts textContent={text} />}
          />
        ))}
    </>
  );
};

export default TextRoutes;
