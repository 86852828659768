import React from "react";

import styled from "styled-components";
import Section from "@components/atoms/Section";
import ContactLinks from "@components/molecules/ContactLinks";
import dataLinks from "@data/links";
import { media, setFlex } from "@global/helper";

const ContactInfo = () => {
  return (
    <Section>
      <ContactInfoWrapper>
        <ContactLinks
          title=""
          links={[dataLinks.instagram]}
          type="icon title"
          left
          direction="column"
        >
          <h3>{dataLinks.instagram.content}</h3>
        </ContactLinks>
        <ContactLinks
          direction="column"
          title=""
          links={[dataLinks.email]}
          type="icon title"
          left
        >
          <h3>{dataLinks.email.content}</h3>
        </ContactLinks>
        <ContactLinks
          title=""
          links={[dataLinks.estudioLiz]}
          type="icon content"
          left
          direction="column"
        >
          <h3>{dataLinks.estudioLiz.content}</h3>
        </ContactLinks>
      </ContactInfoWrapper>
    </Section>
  );
};

const ContactInfoWrapper = styled.div`
  ${media.desktop`
    ${setFlex()};
`}
`;

export default ContactInfo;
