import React from "react";
import styled from "styled-components";

import AboutInfo from "@components/molecules/AboutInfo";
import { media, setRem } from "@global/helper";

const TextContent = ({ title, text, children, ...props }) => {
  return (
    <TextContentWrapper {...props}>
      <AboutInfo title={title} text={text} />
      {children}
    </TextContentWrapper>
  );
};

const TextContentWrapper = styled.div`
  margin: 2vh auto 4vh auto;
  padding: 0 ${setRem(32)};
  width: 90vw;
  ${media.phone`
    padding: 0 ${setRem(64)};
  `}
  ${media.large`
  width: 100%;
  max-width: 1170px;
  `}
`;

export default TextContent;
