import styled from "styled-components";
import { setRem } from "@global/helper";

const Section = styled.section`
  padding: ${(props) =>
    props.padding
      ? props.padding
      : `0 ${setRem(64)} ${setRem(64)} ${setRem(64)}`};
  background: ${({ color }) => color || ""};
`;

export default Section;
