const texts = [
  {
    id: 1,
    title: "Ensolarados",
    content: ` 'Ensaio nas Nuvens Sempre e Outra vez.
    Limpa o Alvo Rompendo Aurora e Dançando ainda, Olho no olho.'
    
    Em cada vez foi a mesma, e, outra história. No tempo sempre diferente. Na forma, sempre a mesma coisa. Três únicas maneiras. Sempre pela manhã, e no calor. Todas as vezes foi o amor suplicando um acalanto doce, um brilho que se repete de forma individual, diferente como são e sempre serão.
    A primeira, às pressas, sem saber o que aconteceria. Bem jovem, a alegria de realizar meu maior sonho. Foi até o final, esperou para o último momento e num rompante de choro foi que te olhei Vitorioso. Teus cabelos fartos e olhos de amêndoas, mãos frágeis que, hoje, são fortes. Calor inesquecível de uma enxurrada que lavou minha alma de tanta emoção. Senti Ele conosco de uma forma que ainda era inédita.
    A segunda, Príncipe Generoso, aguardou a noite terminar e com toda a luz entrou em meu coração Enviado por Ele. Olhinhos doces, esperou tanto que com a tez mudada, ansiava pelo sossego do amparo. Quando te vi, soube o quanto é possível amar e o quanto esse sentimento pode ser multiplicado.
    A terceira, única vez em que ditamos o tempo, vi ali em meus braços o céu mostrando sua luz novamente e, o Vencedor, em altos brados, trazendo movimento e completando o número que fora previsto para nós.
    Movida pelo sol e o calor que dele emana, iluminada a vida. Ensolarados são vocês meus três tesouros, carregados de tanta novidade e aprendizados a cada dia.
    Dias e noites brilham porque vocês existem, dão rumo e ensinam como ser o que sempre quis, da melhor maneira que posso. Sigo aprendendo a ver a luz de cada um de vocês, como foi na primeira vez que nossos olhos se encontraram. Singulares na forma, na cor e na alegria imensurável de ser mãe.`,
  },
  {
    id: 2,
    title: "Prenúncio",
    content: `    Observando do outro lado da praça, sentindo o cheiro da saudade, percebi que Altair, um velho amigo, acabara de chegar. Ao lado de sua bicicleta, exalando o perfume da reflexão, ele estava no banco de sempre, na praça de todos os dias e com o semblante das horas.
    Altair sabia que precisava deixar o vento lamber sua face, também estava ciente que aquele barulho de criança sendo feliz, era o prenúncio dos dias vindouros. Alegre e saudoso, pensava na esposa falecida e na falta de sorte que teve por não conhecer o neto, que em pouco tempo faria a vida dele assumir cores mais suaves e encheria seu coração de bubus e nhéinnhéins.
    Olhando longe, em busca de um brilho que estava logo ali, recordava do tempo em que os filhos nasceram. Naquele espaço, pequenos sabores não eram usufruídos. O tempo em casa era de descanso curto e pouco partilhado. Se havia arrependimento, não estou certo, mas não duvido que a decisão agora, era ver cada sorriso e aquecer-se com as mãos macias e leves daquele que viria acrescentar encanto, ao tempo de várias memórias.`,
  },
  {
    id: 3,
    title: "Na mata",
    content: `    Primeiro foi o silêncio amortecendo meus sentidos e fazendo relaxar, pensei que toda a história de ontem era só a minha cabeça, como sempre. O céu foi aos poucos mergulhando na escuridão e os sons da noite ciciando em meus ouvidos. Precisava beber água. A garganta arranhando e a lembrança dos pãezinhos sobre a mesa me angustiaram.
    Apenas uns passos, mas eu estava amarrada em medo. O movimento denunciaria minha presença viva. Aguentei por muito tempo, calculei o trajeto e os gestos, as sombras e o efeito no ambiente. Controlei tudo, nem respirava, mas o suor teimava em escorrer. Talvez eu fosse descoberta pelo cheiro já que isso eu não poderia esconder.
    Atravessei parte da sala, enrolei uns pães na minha blusa, segurei a garrafa de água e voltei para o canto onde poderia saciar por um tempo a fome e a sede. Satisfeita aguardei, não sabia se ele voltaria, fiquei ali, com os olhos esbugalhados e atenta a tudo. Uma coruja, o vento, as folhas secas no chão.
    
    Senti algo diferente, minha respiração ficou incontrolável, percebi o odor dele. Os pelos fartos e sujos, ofegando e bufando, era insuportável saber da sua proximidade e sentir que estava totalmente entregue. Fui escorregando entre as poltronas, tentando fazer o movimento oposto ao dele, buscando a saída. Precisava me manter oculta por mais um tempo para ter alguma chance.
    
    Estava perto da saída quando ele esbarrou na mesa e fez um tremendo estrondo. Oportunidade. Corri até a porta, ele me viu e soltou um urro assustador. Consegui entrar na mata, o enorme animal peludo, mesmo se arrastando, era mais rápido que eu.
    
    Gritei, ele me alcançou e mostrou toda a sua força me arremessando em uma pedra. O sangue escorreu em meu rosto, meus olhos, agora ferozes, incendiaram de raiva e dor. Agarrei um enorme galho e fiquei aguardando mais uma investida.
    
    Lutei, fui forte, ele era imenso. Agora estou sozinha, deitada no meu sangue, coberta de dor, não sei que parte de mim está inteira e nem por quanto tempo. Ao lado, o cheiro insuportável dessa imensa fera sem vida.`,
  },
  {
    id: 4,
    title: "Amargo",
    content: `    Sentou ao meu lado, anos de amizade, mas naquele dia preferia nunca ter conhecido. Isabel deu um sorriso e perguntou como eu estava. Tinha vontade de responder, mas o que ganharia com isso? Pelo que percebi ela ignorava que eu sabia.   
    Isabel tinha estilo, falava com charme e sabia se vestir. Seu humor era ácido e ficava estampado naquelas covinhas que emolduravam o batom de cor forte. Sempre a admirei mas a ingenuidade embaça a vista.
    A confeitaria era a de sempre, eu é que não era e seria ruim deixar que percebesse o azedo que me consumia. Vesti minha melhor personagem, a louca. Conversamos até eu esquecer minha raiva, uma amizade está acima de certas dores.
    Pedi um salgado delicioso recheado com queijo. Mas aquele café amargo foi um punhal, trazendo as lembranças e impedindo o prazer. Não tive dúvida e docemente clamei: - Por favor, o açucareiro!`,
  },
  {
    id: 5,
    title: "Caminho",
    content: `    Ela virou a página, ele optou por rasgar o livro. Novas linhas foram escritas. Mas eram tortas e a caneta ruim.
    Mexeram no lixo, reviraram as folhas, usaram cola.    
    Novas aventuras começaram, o sol ainda esquentando a mesma janela, os olhares agora com rugas. As mãos unidas voltaram a trilhar um mesmo caminho. Na estrada que escolheram, às vezes florida, as cores se multiplicam e nem sempre é dia.`,
  },
  {
    id: 6,
    title: "Silêncio",
    content: `    A mulher entrou esbarrando na cômoda, tocou o tornozelo apertando os olhos. Era para entrar em silêncio mas deu em nada. O homem resmungou umas palavras, virou na cama e seguiu roncando. (Ótimo!) Tirou a maquiagem depressa e deitou sem roupa.

    O homem passou a noite mirando a falta. Entre uma cerveja e outra, olhava o celular que nada dizia. Mais uma vez o mesmo, horas de escuridão e silêncio. (Sacana!) Dormiu fedendo naquela cama que mais parecia um chiqueiro.
    A luz queimou o quarto. Ela deu um sorriso para ele. Ele devolveu com pranto. O consolo veio da mesma forma de sempre, ela era boa demais.`,
  },
  {
    id: 7,
    title: "(Im)Pulso",
    content: `    Enquanto tiver pulso ficarei com ele, pensou Ana. Tum, tum. Tum, tum. Tum, tum. As estações mostraram suas diferentes cores. Tum, tum. Tum, tum. O aspecto dele não mudou, Ana ficava olhando para sabe lá onde, tentando encontrar alguma mudança. Somente o corpo dela permanecia ali, com suas lembranças. Tum, tum. Não participou do nascimento do terceiro neto, nem foi ao casamento da sobrinha. Foi minguando, e quando ele finalmente acordou, soube que Ana havia partido para encontrá-lo.`,
  },
  {
    id: 8,
    title: "Audiência",
    content: `Mãe? ….. Mãããããe?
    Sim filha, estou aqui em cima, no escritório, porquê?
    Ãh …. Nada.
    Precisa de alguma coisa? - pergunta a mãe após ouvir ruídos abafados no andar de baixo.
    ….
    Priscila? Filha, quer que eu desça?
    …..
    Prisciiiiiiila? - a mãe afasta a cadeira da mesa e vai levantando.
    Não mãe, já resolvi. Tu não tinha audiência?
    É amanhã, te falei, nunca presta atenção, né?
    Merda.
    Priscila! Qual o problema?
    Nada mãe, não consigo gravar tua agenda, só isso.
    Comprei o iogurte que tu gosta, quer fazer um lanche?
    Não tô afim, pode ser mais tarde, quem sabe….
    O que há filha? - começa a descer a escada.
    Nada mãe, que saco! Para de perguntar, já te pedi para não ficar toda hora perguntando se tá tudo bem, qual o problema? Sério? Isso enche! - a mãe se aproxima, a filha desvia os olhos.
    Priscila, pergunto me importo, poderia nem me importar, seria melhor?
    Com certeza! - os olhos da filha estão fixos no chão.
    ….
    Desculpe mãe...não quis dizer isso.
    
    Entra no quarto e o estrondo machuca.
    A mãe cola o ouvido na porta. Volta para a escada e faz barulho com os pés, em silêncio se aproxima de novo da porta e fica ali, com a mão na maçaneta.
    
    Eu sei que tu tá aí mãe….
    ….
    …..
    Abre logo essa porta Priscila!
    Tá aberta mãe….
    
    A mãe tranca a porta por dentro.
    Que foi agora mãe?
    Tem alguém lá embaixo… ouvi alguma coisa.
    Nada mãe, que bobagem. (Será que o João não saiu? - pensa Priscila)
    Tenho certeza, ouvi passos, fica quieta pra gente ouvir.
    Tá brincando, né?
    Claro que não, não brinco com isso.
    Tá mãe, senta aí, tá carente, né?
    Não Priscila, tem alguém na nossa casa, estou preocupada. Manda uma mensagem para o teu pai. Vai chamando a polícia, vou pensar o que fazer.
    Se tem mesmo alguém, vamos ficar aqui.
    E vamos esperar nos roubarem?
    Melhor roubada e viva.
    E o teu pai?
    O que tem?
    Já mandou a mensagem?
    Ãh...não.
    Porque?
    Sei lá, acho que não tem ninguém lá embaixo.
    
    A porta da casa bate.
    
    Olha aí, na janela, tem um guri saindo…. É, é, é…
    Quem mãe?
    Nunca vi.
    …. - Priscila fica vermelha.
    Tá bom Priscila, a audiência é amanhã…`,
  },
  {
    id: 9,
    title: "No lugar certo",
    content: `    Sentiu o amor escorrer por tudo, espalhando-se.
    Foi um golpe só, uma pancada, o líquido esvaziou o corpo.
    Ela assistiu deliciando-se.
    Não precisava mais fugir, estava no lugar certo.`,
  },
];

export default texts;
