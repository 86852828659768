import React from "react";
import styled from "styled-components";
import {
  setColor,
  setLinkStyle,
  setRem,
  setTransition,
  media,
  setFlex,
} from "@global/helper";

const NavItem = ({
  className,
  path,
  children,
  noCapitalize,
  logo,
  ...props
}) => {
  return (
    <a className={className} href={path} {...props}>
      {children}
    </a>
  );
};

export default styled(NavItem)`
  ${setFlex()};
  ${(props) => (props.noCapitalize ? "" : `text-transform: capitalize;`)};
  text-decoration: none;
  font-weight: bolder;
  padding: ${setRem(10)};
  ${setTransition()};
  text-align: center;

  * {
    margin: 0 ${setRem(4)};
  }

  ${(props) => {
    return props.logo
      ? setLinkStyle({
          colorBg: setColor.primaryColor,
          colorFont: setColor.secondaryColor,
          fontSize: setRem(16),
        })
      : setLinkStyle({
          colorBg: setColor.secondaryColor,
          colorFont: setColor.primaryColor,
          fontSize: setRem(12),
        });
  }};
  ${media.phone`
      font-size: ${(props) => (props.logo ? setRem(32) : setRem(20))};
      padding: ${setRem(12)};
  `}
  ${media.tablet`
      font-size: ${(props) => (props.logo ? setRem(40) : setRem(26))};
      padding: ${setRem(16)};
  `}
`;
