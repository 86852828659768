import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import { setColor, setRem, setShadow, setTransition } from "@global/helper";

const BookCard = ({ book }) => {
  const {
    img = "",
    title = "",
    author = "",
    kind = "",
    year = "",
    link = "/",
  } = book;
  return (
    <BookCardWrapper href={link} target="_blank">
      <div className="book-img">
        <img loading="lazy" src={img} alt={title}></img>
        <div className="book-infos">
          <h2 className="infos-title">{title}</h2>
          <h4>{author}</h4>
          <h4>
            {kind} | <span> {year}</span>
          </h4>
        </div>
      </div>
    </BookCardWrapper>
  );
};

const BookCardWrapper = styled.a`
  background: ${setColor.primaryColor};
  ${setShadow.light};
  ${setTransition()};
  color: ${setColor.secondaryColor};
  text-decoration: none;
  max-width: 450px;
  text-align: center;
  margin: 2vh 0;

  .book-img {
    margin: 0 auto;
    img {
      width: 100%;
      display: block;
    }
  }

  .book-infos {
    padding: ${setRem(16)};
    .infos-title {
      padding-bottom: ${setRem(12)};
    }
  }

  &:hover {
    ${setShadow.darkest};
  }
`;

export default BookCard;

BookCard.propTypes = {
  book: PropTypes.shape({
    img: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    author: PropTypes.string.isRequired,
    year: PropTypes.string.isRequired,
    kind: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
  }),
};
