import React, { useState } from "react";
import styled from "styled-components";

import NavItem from "@components/atoms/NavItem";
import Section from "@components/atoms/Section";
import Title from "@components/atoms/Title";

import { setFlex, setRem } from "@global/helper";

const ContactLinks = ({
  children,
  title = "",
  links = [{ title: "", link: "" }],
  type = "title",
  ...props
}) => {
  const [arrayLinks] = useState(links);

  return (
    <Section padding={setRem(16)}>
      <Title title={title} size={setRem(24)} center />
      <ContactLinkWrapper {...props}>
        {arrayLinks &&
          arrayLinks.map((link, index) => {
            const linkShow = (link) => {
              const typeArray = type.split(" ").map((e) => link[`${e}`]);
              return typeArray;
            };
            return (
              <NavItem
                key={index}
                path={link.link}
                target="_blank"
                noCapitalize
              >
                {linkShow(link).map((e, index) => {
                  return <div key={index}>{e}</div>;
                })}
              </NavItem>
            );
          })}
        {children}
      </ContactLinkWrapper>
    </Section>
  );
};
const ContactLinkWrapper = styled.div`
  ${setFlex()};
  ${(props) => (props.left ? setFlex({ x: "left" }) : "")};
  text-align: center;
  flex-direction: ${(props) => (props.direction ? props.direction : "")};

  a {
    margin: 1vh 1vw;
  }
`;

export default ContactLinks;
