import React from "react";
import styled from "styled-components";
import { setBorder, setColor, setFlex, setRem } from "@global/helper";

const ProfilePhoto = ({ img, borderColor }) => {
  return (
    <ImgWrapper>
      <img loading="lazy" src={img} alt="Foto de perfil"></img>
    </ImgWrapper>
  );
};

const ImgWrapper = styled.div`
  padding: 0 ${setRem(64)};
  ${setFlex()};
  img {
    width: 100%;
    min-width: 100px;
    max-width: 350px;
    display: block;
    ${(props) => {
      return setBorder({ width: "7px", color: setColor.secondaryColor });
    }};
    border-radius: ${setRem(160)};
  }
`;

export default ProfilePhoto;
