import React from "react";
import styled from "styled-components";

import Title from "@components/atoms/Title";
import { media, setLetterSpacing, setRem } from "@global/helper";

const AboutInfo = ({ className, title, text }) => {
  return (
    <div className={`${className} about-info`}>
      <Title title={title} center size={setRem(28)} />
      <p>{text}</p>
    </div>
  );
};

export default styled(AboutInfo)`
  ${setLetterSpacing(2.3)};
  width: 100%;
  p {
    font-size: ${setRem(14)};
  }

  ${media.phone`
    p {
      font-size: ${setRem(18)};
    }
  `}
`;
