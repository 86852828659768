import React, { useState } from "react";
import styled from "styled-components";

import { setRem, media } from "@global/helper";
import textsData from "@data/texts";
import Section from "@components/atoms/Section";
import Title from "@components/atoms/Title";
import NavItem from "@components/atoms/NavItem";

const TextLinks = () => {
  const [texts] = useState(textsData);
  return (
    <Section padding={setRem(10)}>
      <Title title="Textos" size={setRem(40)} center />
      <TextLinksWrapper>
        {texts.length > 0 &&
          texts.map((text) => {
            return (
              <NavItem
                className="link-text"
                key={text.id}
                path={`/textos/${text.id}`}
                noCapitalize
              >
                {text.title}
              </NavItem>
            );
          })}
      </TextLinksWrapper>
    </Section>
  );
};

const TextLinksWrapper = styled.nav`
  width: 70%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-column-gap: ${setRem(32)};
  grid-row-gap: ${setRem(16)};

  .link-text {
    margin: ${setRem(8)} 0;
  }
  ${media.large`
  grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));
    width: 100%;
    max-width: 1170px;  
  `}
`;
export default TextLinks;
