import React, { useState } from "react";
import styled from "styled-components";

import Section from "@components/atoms/Section";
import Title from "@components/atoms/Title";
import BookCard from "@components/atoms/BookCard";
import { setRem, media } from "@global/helper";

import booksData from "@data/books";

const BooksCardsSection = () => {
  const [books] = useState(booksData);
  return (
    <Section padding={setRem(32)}>
      <Title title="Livros" center size={setRem(48)} />
      <BookCardsWrapper>
        {books.length > 0 &&
          books.map((book) => {
            return <BookCard key={book.id} book={book} />;
          })}
      </BookCardsWrapper>
    </Section>
  );
};

const BookCardsWrapper = styled.div`
  margin: 0 auto;
  width: 90%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-column-gap: ${setRem(32)};

  ${media.large`
    width: 100%;
    max-width: 1170px;  
  `}
`;

export default BooksCardsSection;
