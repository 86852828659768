import React from "react";

import { setColor, setRem } from "@global/helper";
import Section from "@components/atoms/Section";
import BookCardsSection from "@components/organisms/BookCardsSection";

const Books = () => {
  return (
    <Section
      color={`linear-gradient(
    ${setColor.primaryColor}80,
    ${setColor.primaryColor}e0
  )`}
      padding={`${setRem(32)}`}
    >
      <BookCardsSection />
    </Section>
  );
};

export default Books;
